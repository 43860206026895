<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Message Template</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="editMessageTemplate">
                                <label>Title</label>
                                <input type="text" class="form-control" v-model="form.title" readonly />
                                <br>
                                <label>Message</label>
                                <textarea cols="30" class="form-control"  rows="10" v-model="form.message"></textarea>
                                <br>
                                <div class="button-group d-flex pt-25">
                                    <button type="submit"
                                        class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                        simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        data() {
            return {
                id: '',
                form: {},
                packages: [],
                isLoading: true,
            };
        },
        async mounted() {
            await this.loadData()
            this.id = this.$route.query.id
        },
        methods: {
            async editMessageTemplate(){
                try{
                    let response = await this.$store.dispatch('admin_message_template_update', this.form)
                    this.$toast.success(response.data.message);

                    await this.loadData()
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            async loadData() {
                try {
                    let id = this.$route.query.id
                    let detail = await this.$store.dispatch("admin_message_template_detail", {
                        id: id
                    })
                    this.form = detail.data.data.template_message

                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/admin/coupon")
                }
            },
        },
    };
</script>

<style scoped>
    
</style>