<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            perPageDropdown: [10, 25, 50, 100],
          }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'username'">
                    {{ props.row.user.username }}
                </span>
                <span v-else-if="props.column.field == 'nominal'">
                    {{ formatRupiah(props.row.nominal) }} - {{ formatRupiah(setting.withdraw_fee) }} (Fee Withdraw)
                    <br>
                    = {{ formatRupiah(props.row.nominal-setting.withdraw_fee) }}
                </span>
                <span v-else-if="props.column.field == 'created_at'">
                    {{ convertTimezone(props.row.created_at) }}
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <span v-if="props.row.status == 'success'" class="badge badge-success">Approved</span>
                    <span v-if="props.row.status == 'pending'" class="badge badge-warning text-white">Pending</span>
                    <span v-if="props.row.status == 'failed'" class="badge badge-danger text-white">Rejected</span>
                </span>
                <span v-else-if="props.column.field == 'action'">
                    <div v-if="props.row.status == 'pending'">
                        <a v-on:click="approveWithdraw(props.row.id)" class="btn btn-success text-white margin5">
                            Approve
                        </a>
                        <a v-on:click="rejectWithdraw(props.row.id)" class="btn btn-danger text-white margin5">
                            Reject
                        </a>
                    </div>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                setting: {},
                table: {
                    total_records: 0,
                    isLoading: true,
                    columns: [{
                            label: "Username",
                            field: "username",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Bank",
                            field: "bank",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Nama Rekening",
                            field: "nama_rekening",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "15%",
                        },
                        {
                            label: "Nomor Rekening",
                            field: "nomor_rekening",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Nominal",
                            field: "nominal",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "15%",
                        },
                        {
                            label: "Tanggal",
                            field: "created_at",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Status",
                            field: "status",
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        limit: 10,
                    },
                },
            };
        },
        mounted() {
            this.fn = () => {
                this.loadItems()
            }
            this.$root.$on("refresh_table", this.fn)
            this.loadItems()
            this.loadPengaturan()
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fn);
        },
        methods: {
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
            convertTimezone(utc) {
                return moment.unix(utc / 1000).format("YYYY-MM-DD HH:mm:ss")
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    limit: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.table.isLoading = true
                try {
                    let response = await this.getFromServer(this.table.serverParams);
                    this.table.total_records = response.data.data.total_records;
                    this.table.rows = response.data.data.withdraws;
                } catch (err) {
                    this.$toast.error("Too many request");
                }
                this.table.isLoading = false
            },
            async getFromServer(param) {
                return await this.$store.dispatch("withdraw_list", param);
            },
            async loadPengaturan() {
                try {
                    let setting = await this.$store.dispatch("admin_setting")
                    this.setting = setting.data.data.setting
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async approveWithdraw(id) {
                try {
                    let response = await this.$store.dispatch("withdraw_approve", {
                        id: id
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
            async rejectWithdraw(id) {
                try {
                    let response = await this.$store.dispatch("withdraw_reject", {
                        id: id
                    });
                    this.loadItems();
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error("Server error");
                }
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>