<template>
    <div>
        <div style="text-align: center">
            <h3>Tambah User</h3>
        </div>
        <br />
        <form v-on:submit.prevent="addUser">
            <label>Paket</label>
            <multiselect v-model="form.package" tag-placeholder="Pilih Whitelist Paket"
                    placeholder="Cari Paket" label="name" track-by="id" :options="packages"
                    :multiple="false"></multiselect>
            <br />
            <label>Name</label>
            <input type="text" class="form-control" v-model="form.name" required />
            <br>
            <label>Username</label>
            <input type="text" class="form-control" v-model="form.username" required />
            <br>
            <label>Email</label>
            <input type="text" class="form-control" v-model="form.email" required />
            <br>
            <label>Phone</label>
            <input type="number" class="form-control" v-model="form.phone" required />
            <br>
            <label>Password</label>
            <input type="password" class="form-control" v-model="form.password" required />
            <br>
            <div class="button-group d-flex pt-25">
                <button type="submit"
                    class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                    simpan
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    package: "",
                    username: "",
                    email: "",
                    phone: "",
                    password: "",
                },
                packages: [],
                null_value: null
            };
        },
        mounted() {
            this.getPackage()
        },
        methods: {
            toggleModal(param) {
                this.$modal.show(param);
            },
            async getPackage() {
                try {
                    let p = await this.$store.dispatch('package_list')
                    this.packages = p.data.data.packages ?? []
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async addUser(){
                try{
                    this.form.package = parseInt(this.form.package.id)
                    let response = await this.$store.dispatch('admin_user_create', this.form)
                    this.$toast.success(response.data.message);
                    this.$root.$emit("toggle_modal", "new_user")
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    
</style>