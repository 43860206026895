<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            perPageDropdown: [10, 25, 50, 100],
          }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'username'">
                    {{ props.row.user.username }}
                </span>
                <span v-else-if="props.column.field == 'email'">
                    {{ props.row.user.email }}
                </span>
                <span v-else-if="props.column.field == 'phone'">
                    {{ props.row.user.phone }}
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <span v-if="props.row.status == 'success'" class="badge badge-success">Sukses</span>
                    <span v-if="props.row.status == 'pending'" class="badge badge-warning text-white">Pending</span>
                    <span v-if="props.row.status == 'failed'" class="badge badge-danger text-white">Gagal</span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        props: ["date"],
        data() {
            return {
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Username",
                            field: "username",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Email",
                            field: "email",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Phone",
                            field: "phone",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Pesan Keluar",
                            field: "message_sent",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Pesan Masuk",
                            field: "incoming_message",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        date: moment().format("YYYY-MM-DD"),
                        page: 1,
                        limit: 10,
                    },
                },
            };
        },
        watch: {
            date: {
                handler(v){
                    this.table.serverParams.date = moment.utc(v).format("YYYY-MM-DD")
                    this.loadItems()
                },
                deep: true
            }
        },
        mounted() {
            this.fn = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fn);
            this.loadItems();
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fn);
        },
        methods: {
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
            convertTimezone(utc) {
                return moment.unix(utc / 1000).format("YYYY-MM-DD HH:mm:ss")
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    limit: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.table.isLoading = true
                try {
                    let response = await this.getFromServer(this.table.serverParams);
                    this.table.total_records = response.data.data.total_records;
                    this.table.rows = response.data.data.statistics;
                } catch (err) {
                    this.$toast.error("Too many request");
                }
                this.table.isLoading = false
            },
            async getFromServer(param) {
                return await this.$store.dispatch("statistics_list", param);
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>