<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail User</h4>
                                </div>
                            </div>
                            <div class="action-btn">
                                <a v-on:click="toggleModal('addSaldo')" class="btn px-15 btn-success text-white"
                                    style="display: inline-flex; margin: 5px">
                                    <i class="fas fa-plus fs-16"></i>
                                    Tambah Saldo
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="editUser">
                                <label>Username</label>
                                <input type="text" class="form-control" v-model="form.user.username" required />
                                <br>
                                <label>Email</label>
                                <input type="text" class="form-control" v-model="form.user.email" required />
                                <br>
                                <label>Phone</label>
                                <input type="number" class="form-control" v-model="form.user.phone" required />
                                <br>
                                <label>Balance</label>
                                <money class="form-control" v-model="form.user.balance" v-bind="money"></money>
                                <br>
                                <label>Withdrawable Balance</label>
                                <money class="form-control" v-model="form.user.withdrawable_balance" v-bind="money"></money>
                                <br>
                                <label>Password (kosongkan jika tidak ingin mengubah password)</label>
                                <input type="text" class="form-control" v-model="form.user.password" />
                                <br>
                                <label>Status</label>
                                <select class="form-control" v-model="form.user.status">
                                    <option value="0">Belum Terverifikasi</option>
                                    <option value="1">Aktif</option>
                                    <option value="2">Banned</option>
                                </select>
                                <br>
                                <h3>Overwrite Paket</h3>
                                <br>
                                <label>Lisensi</label>
                                <input type="number" class="form-control" v-model="form.user_package.lisensi"
                                    required />
                                <br>
                                <label>Message Quota</label>
                                <input type="number" class="form-control" v-model="form.user_package.message_quota"
                                    required />
                                <br>
                                <label>Max Device</label>
                                <input type="number" class="form-control" v-model="form.user_package.max_device"
                                    required />
                                <br>
                                <label>Max CS</label>
                                <input type="number" class="form-control" v-model="form.user_package.max_cs" required />
                                <br>
                                <label>Max Campaign</label>
                                <input type="number" class="form-control" v-model="form.user_package.max_campaign"
                                    required />
                                <br>
                                <label>Max Order Online</label>
                                <input type="number" class="form-control" v-model="form.user_package.max_order_online"
                                    required />
                                <br>
                                <label>Max Product Link Rotator</label>
                                <input type="number" class="form-control" v-model="form.user_package.max_link_rotator"
                                    required />
                                <br>
                                <label>Max Chat Widget</label>
                                <input type="number" class="form-control" v-model="form.user_package.max_chat_widget"
                                    required />
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label>Paket Sekarang</label>
                                        <input type="text" class="form-control"
                                            v-model="form.user_package.package_data.name" readonly />
                                        <br>
                                        <label>Expired</label>
                                        <input type="text" class="form-control" :value="formatDate(form.user_package.expired_at)"
                                            readonly />
                                    </div>
                                    <div class="col-md-6">
                                        <label>Paket Baru (kosongkan jika tidak ingin mengubah)</label>
                                        <multiselect v-model="form.new_package"
                                            tag-placeholder="Pilih Whitelist Paket" placeholder="Cari Paket"
                                            label="name" track-by="id" :options="packages" :multiple="false">
                                        </multiselect>
                                        <br>
                                        <label>Expired Baru</label>
                                        <v-date-picker class="inline-block h-full" v-model="form.new_expired" mode="date">
                                            <template v-slot="{ inputValue, togglePopover }">
                                                <div>
                                                    <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                                                </div>
                                            </template>
                                        </v-date-picker>   
                                    </div>
                                </div>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <button style="margin:5px" v-on:click="toggleModal('perpanjangKadaluwarsa')" type="button" class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                            Perpanjang Kadaluwarsa
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <button type="submit" style="margin:5px"
                                            class="btn btn-success btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                            Simpan
                                        </button>
                                    </div>
                                    <div class="col-md-12">
                                        <router-link to="/admin/users">
                                            <a style="margin:5px" type="button" class="btn btn-warning btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                                                Kembali
                                            </a>
                                        </router-link>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="addSaldo" :height="'auto'" :adaptive="true" :scrollable="true">
            <div style="margin: 20px">
                <center>
                    <h2>Tambah Saldo User</h2>
                </center>
                <br>
                <form  v-on:submit.prevent="addSaldo">
                    <label for="">Nominal Saldo</label>
                    <money class="form-control" v-model="balance" v-bind="money"></money>
                    <br>
                    <button type="submit" class="btn btn-success btn-block">Konfirmasi</button>
                </form>
            </div>
        </modal>
        <modal name="perpanjangKadaluwarsa" :height="'auto'" :adaptive="true" :scrollable="true">
            <div style="margin: 20px">
                <center>
                    <h2>Perpanjang Kadaluwarsa</h2>
                </center>
                <br>
                <form  v-on:submit.prevent="extendExpired">
                    <div class="row">
                        <div class="col-md-6">
                            <input v-model="extendExpiredForm.val" type="number" class="form-control" min="0" placeholder="value" required="">
                        </div>
                        <div class="col-md-6">
                            <select v-model="extendExpiredForm.type" id="" class="form-control">
                                <option value="hari">Hari</option>
                                <option value="minggu">Minggu</option>
                                <option value="bulan">Bulan</option>
                                <option value="tahun">Tahun</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <button type="submit" class="btn btn-success btn-block">Perpanjang</button>
                </form>
            </div>
        </modal>
    </div>
</template>

<script>
    import moment from 'moment'
    import Multiselect from 'vue-multiselect'
    import { Money } from 'v-money'

    export default {
        components: {
            Multiselect,
            Money
        },
        data() {
            return {
                id: '',
                form: {},
                balance: 0,
                packages: [],
                money: {
                    thousands: '.',
                    precision: 0,
                },
                extendExpiredForm: {
                    val: 0,
                    type: 'hari'
                },
                isLoading: true,
                
            };
        },
        async mounted() {
            await this.loadData()
            await this.getPackage()
            this.id = this.$route.query.id
        },
        methods: {
            async editUser() {
                try {
                    let formatedDate = moment(this.form.new_expired).format("YYYY-MM-DD")
                    this.form.new_expired = parseInt(moment(formatedDate).utc().valueOf())
                    
                    let awalExpire = this.formatDate(this.form.user_package.expired_at)
                    let tahunDepan = moment(awalExpire).add(1,'y')
                    let kelebihan = moment(formatedDate).isAfter(tahunDepan)
					
                    if(localStorage.role != 'admin' && kelebihan){
                        this.$toast.error("Maaf Perpanjang tidak boleh lebih dari 1 tahun")
                        return
                    }

                    this.form.user.balance = parseInt(this.form.user.balance)
                    this.form.user.withdrawable_balance = parseInt(this.form.user.withdrawable_balance)
                    this.form.user_package.lisensi = parseInt(this.form.user_package.lisensi)
                    this.form.user_package.message_quota = parseInt(this.form.user_package.message_quota)
                    this.form.user_package.max_device = parseInt(this.form.user_package.max_device)
                    this.form.user_package.max_cs = parseInt(this.form.user_package.max_cs)
                    this.form.user_package.max_campaign = parseInt(this.form.user_package.max_campaign)
                    this.form.user_package.max_order_online = parseInt(this.form.user_package.max_order_online)
                    this.form.user_package.max_link_rotator = parseInt(this.form.user_package.max_link_rotator)
                    this.form.user_package.max_chat_widget = parseInt(this.form.user_package.max_chat_widget)

                    let response = await this.$store.dispatch('admin_user_update', this.form)
                    this.$toast.success(response.data.message);

                    await this.loadData()
                    await this.getPackage()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async addSaldo(){
                try{
                    let response = await this.$store.dispatch('admin_user_add_balance', {
                        id: this.id,
                        balance: parseInt(this.balance)
                    })
                    this.$toast.success(response.data.message);
                    await this.loadData()

                    this.toggleModal('addSaldo')
                }catch(err){
                    this.$toast.error(err?.response?.data?.message);
                }
            },
            async loadData() {
                try {
                    let id = this.$route.query.id
                    let detail = await this.$store.dispatch("admin_user_one", {
                        id: id
                    })
                    this.form = detail.data.data
                    this.form.new_expired = this.form.user_package.expired_at
                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/admin/coupon")
                }
            },
            async getPackage() {
                try {
                    let p = await this.$store.dispatch('package_list')
                    this.packages = p.data.data.packages ?? []
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            formatDate(val) {
                return moment.unix(val / 1000).format('YYYY-MM-DD')
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async extendExpired(){
                let val = parseInt(this.extendExpiredForm.val)
                let unixMilis = 0

                if(localStorage.role != 'admin'){
                    if(val>1 && this.extendExpiredForm.type == 'tahun'){
                        this.$toast.error("Maaf Perpanjang tidak boleh lebih dari 1 tahun");
                        return
                    }else if(val>12 && this.extendExpiredForm.type == 'bulan'){
                        this.$toast.error("Maaf Perpanjang tidak boleh lebih dari 12 bulan");
                        return
                    }else if(val>52 && this.extendExpiredForm.type == 'minggu'){
                        this.$toast.error("Maaf Perpanjang tidak boleh lebih dari 52 minggu");
                        return
                    }else if(val>365 && this.extendExpiredForm.type == 'hari'){
                        this.$toast.error("Maaf Perpanjang tidak boleh lebih dari 365 hari");
                        return
                    }
				
				}

                if(this.extendExpiredForm.type == "hari"){
                    unixMilis = 1000 * 60 * 60 * 24 * val
                }else if(this.extendExpiredForm.type == "minggu"){
                    unixMilis = 1000 * 60 * 60 * 24 * 7 * val
                }else if(this.extendExpiredForm.type == "bulan"){
                    unixMilis = 1000 * 60 * 60 * 24 * 30 * val
                }else if(this.extendExpiredForm.type == "tahun"){
                    unixMilis = 1000 * 60 * 60 * 24 * 365 * val
                }else{
                    unixMilis = 1000 * 60 * 60 * 24 * 30 * val
                }

                if(isNaN(parseInt(this.form.new_expired)))
                    this.form.new_expired = Date.parse(this.form.new_expired )

                this.form.new_expired = parseInt(this.form.new_expired) + parseInt(unixMilis)
                let response = await this.$store.dispatch('admin_user_update', this.form)
                this.$toast.success(response.data.message);

                await this.loadData()
                await this.getPackage()

                this.toggleModal("perpanjangKadaluwarsa")
            }
        },
    };
</script>

<style scoped>
    
</style>