<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    Device Diskonek
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <br>
                    <v-date-picker class="inline-block h-full" v-model="filter.date" mode="date"
                        :show-current="true">
                        <template v-slot="{ inputValue, togglePopover }">
                            <div>
                                <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <StatisticsTable :date="filter.date"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import StatisticsTable from "@/components/tables/DeviceDiskonek.vue";
    import moment from 'moment'

    export default {
        components: {
            StatisticsTable,
        },
        data() {
            return {
                filter: {
                    date: moment().format("YYYY-MM-DD"),
                }
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
        },
        methods: {
            async loadGroups() {
                try {
                    let response = await this.$store.dispatch('group_list_dropdown')
                    this.group_dropdown = response.data.data.groups
                } catch (err) {
                    this.$toast.error("Server error")
                }
            },
            async emptyContact() {
                if (confirm("Semua data kontak akan terhapus, Yakin ?")) {
                    try {
                        let response = await this.$store.dispatch('contact_empty')
                        this.$toast.success(response.data.message);
                        this.$root.$emit("refresh_table", true)
                    } catch (err) {
                        this.$toast.error(err.response.data.message);
                    }
                }
            },
            async exportContact() {
                try {
                    this.$toast.info("Mohon Tunggu...");

                    await this.$store.dispatch('outgoing_export')
                        .then((response) => {
                            this.exportJson = response.data.data.outgoings

                            for (let i = 0; i < this.exportJson.length; i++) {
                                this.exportJson[i].jadwal = moment.utc(this.exportJson[i].jadwal).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].delivery_schedule = moment.utc(this.exportJson[i].delivery_schedule).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].created_at = moment.utc(this.exportJson[i].created_at).local().format("YYYY-MM-DD HH:mm:ss")
                                this.exportJson[i].updated_at = moment.utc(this.exportJson[i].updated_at).local().format("YYYY-MM-DD HH:mm:ss")
                            }
                        })
                        .then(() => {
                            this.$refs.exportOutgoing.$el.click()
                        })
                } catch (err) {
                    this.$toast.error(err);
                }
            },
            fillVar(val) {
                return val
            },
            toggleModal(param) {
                if (this.group_dropdown.length == 0) this.loadGroups()

                this.$modal.toggle(param);
            },
        },
    };
</script>
