<template>
    <div>
        <div style="text-align: center">
            <h3>Tambah Coupon</h3>
        </div>
        <br />
        <form v-on:submit.prevent="addCoupon">
            <label>Whitelist Paket*</label>
            <multiselect v-model="form.whitelist" tag-placeholder="Pilih Whitelist Paket"
                    placeholder="Cari Paket" label="name" track-by="id" :options="packages"
                    :multiple="true"></multiselect>
            <br />
            <label>Nama Coupon*</label>
            <input type="text" class="form-control" v-model="form.name" required />
            <br>
            <label>Kode Coupon*</label>
            <input type="text" class="form-control" v-model="form.code" required />
            <br>
            <label>Diskon</label>
            <input type="number" class="form-control" v-model="form.discount" required />
            <br>
            <div class="form-check">
                <input class="form-check-input" type="radio" v-model="form.mode" value="1" checked>
                <label class="form-check-label" for="mode">
                    Potongan Nominal
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" v-model="form.mode" value="2">
                <label class="form-check-label" for="mode2">
                    Potongan Persen
                </label>
            </div>
            <br>
            <label for="">Max Kuota *</label>
            <input type="number" class="form-control" v-model="form.max_quota" required>
            <br>
            <label for="">Expired</label>
            <v-date-picker class="inline-block h-full" v-model="form.expired_at" mode="date">
                <template v-slot="{ inputValue, togglePopover }">
                    <div>
                        <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                    </div>
                </template>
            </v-date-picker>            
            <br>
            <div class="form-check">
                <input class="form-check-input" v-model="form.new_member" type="checkbox" value="1" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    New Member Only
                </label>
            </div>
            <br>
            <div class="button-group d-flex pt-25">
                <button type="submit"
                    class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
                    simpan
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import moment from 'moment'

    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    whitelist: "",
                    name: "",
                    code: "",
                    discount: "",
                    mode: 1,
                    max_quota: 0,
                    expired_at: "",
                    new_member: 0,
                },
                packages: [],
                null_value: null
            };
        },
        mounted() {
            this.getPackage()
        },
        methods: {
            toggleModal(param) {
                this.$modal.show(param);
            },
            async getPackage() {
                try {
                    let p = await this.$store.dispatch('package_list')
                    this.packages = p.data.data.packages ?? []
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async addCoupon(){
                try{
                    this.form.discount = parseInt(this.form.discount)
                    this.form.max_quota = parseInt(this.form.max_quota)
                    this.form.mode = parseInt(this.form.mode)
                    this.form.new_member = this.form.new_member ? 1 : 0
                    this.form.expired_at = parseInt(moment(this.form.expired_at).utc().valueOf())

                    let whitelist = []
                    for (let i = 0; i < this.form.whitelist.length; i++) {
                        whitelist.push({id: this.form.whitelist[i].id})
                    }

                    this.form.whitelist = whitelist

                    let response = await this.$store.dispatch('admin_coupon_create', this.form)
                    this.$toast.success(response.data.message);
                    this.$root.$emit("toggle_modal", "new_coupon")
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    
</style>